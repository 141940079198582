<template>
  <div class="my-tabs">
    <v-tabs
      v-model="tab"
      background-color="cyan lighten-5"
      color="indigo darken-4"
      @change="cambioTab"
      class="prueba"
      slider-color="indigo darken-4"
      vertical
      height="100%"
      style="height: 100%"
    >
      <v-tab v-if="!free">STUDENT GUIDES</v-tab>
      <v-tab v-if="!free">Syllable type cards</v-tab>
      <v-tab>Phonics Cards</v-tab>
      <v-tab>READING WORDS</v-tab>
      <v-tab v-if="!free" v-show="L1">Achievement Certificates</v-tab>
      <v-tab v-if="!free">Lee Lee Scope and sequence</v-tab>
      <v-tab v-if="!free">Lee Lee Phonics BINGO</v-tab>
      <v-tab v-if="!free">Other Resources</v-tab>
    <!-- </v-tabs>
    <v-tabs-items v-model="tab"> -->
      <v-tab-item v-if="!free">
        <v-card color="basil" flat>
          <v-container>
            <v-row>
              <v-col
                v-for="resource in filteredResources('STUDENT GUIDES')"
                :key="resource.ResourceID"
                class="mt-5"                
                v-show="resourceShowCondition(resource)"
              >
                <v-card class="mx-auto" max-width="300">
                  <v-img
                    :src="require('../../../assets/admin/'+resource.imgSrc)"
                    :height="resource.Height"
                  ></v-img>

                  <p class="text-center text-h6 mb-0">{{ resource.Title }}</p>
                  <p class="text-center subtitle-1 cyan lighten-5 mb-0 font-weight-bold">
                    {{ resource.Subtitle }}
                  </p>
                  <v-card-actions>
                    <v-tooltip v-if="resource.demo && demo" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="display: inline-block; width: 100%;">
                          <v-btn
                            color="primary"
                            width="100%"
                            :href="resource.url"
                            @click="SaveDownload(resource)"
                            :loading="resource.loading"
                            :disabled="resource.loading || resource.demo"
                            target="_blank"
                            download
                          >
                            download
                          </v-btn>
                        </div>
                      </template>
                      <span>Only with subscription</span>
                    </v-tooltip>
                    <v-btn
                      v-else
                      color="primary"
                      width="100%"
                      :href="resource.url"
                      @click="SaveDownload(resource)"
                      :loading="resource.loading"
                      :disabled="resource.loading"
                      target="_blank"
                      download
                    >
                      <v-icon>mdi-download</v-icon> Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>              
            </v-row>
          </v-container>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="!free">
        <v-card color="basil" flat>
          <v-container>
            <v-row>
              <v-col
                v-for="resource in filteredResources('Syllable type cards')"
                :key="resource.ResourceID"
                class="mt-5"
                cols="6"                
                v-show="resourceShowCondition(resource)"
              >
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    :src="require('../../../assets/admin/'+resource.imgSrc)"
                    :height="resource.Height"
                  ></v-img>
                  
                  <p class="text-center subtitle-1 cyan lighten-5 mb-0 font-weight-bold">
                    {{ resource.Title }}
                  </p>
                  <v-card-actions>
                    <v-tooltip v-if="resource.demo && demo" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="display: inline-block; width: 100%;">
                          <v-btn
                            color="primary"
                            width="100%"
                            :href="resource.url"
                            @click="SaveDownload(resource)"
                            :loading="resource.loading"
                            :disabled="resource.loading || resource.demo"
                            target="_blank"
                            download
                          >
                            download
                          </v-btn>
                        </div>
                      </template>
                      <span>Only with subscription</span>
                    </v-tooltip>
                    <v-btn
                      v-else
                      color="primary"
                      width="100%"
                      :href="resource.url"
                      @click="SaveDownload(resource)"
                      :loading="resource.loading"
                      :disabled="resource.loading"
                      target="_blank"
                      download
                    >
                      <v-icon>mdi-download</v-icon> Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>              
            </v-row>
          </v-container>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card color="basil" flat>
          <v-container>
            <v-row>
              <v-col
                v-for="(resource, index) in filteredResources('Phonics Cards')"
                :key="resource.ResourceID"
                class="mt-5"    
                :cols="index === 0 ? 12 : 6"                           
                v-show="resourceShowCondition(resource)"
              >
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    :src="require('../../../assets/admin/'+resource.imgSrc)"
                    :height="resource.Height"
                  ></v-img>
                  
                  <p class="text-center subtitle-1 cyan lighten-5 mb-0 font-weight-bold">
                    {{ resource.Title }}
                  </p>
                  <v-card-actions>
                    <v-tooltip v-if="resource.demo && demo" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="display: inline-block; width: 100%;">
                          <v-btn
                            color="primary"
                            width="100%"
                            :href="resource.url"
                            @click="SaveDownload(resource)"
                            :loading="resource.loading"
                            :disabled="resource.loading || resource.demo"
                            target="_blank"
                            download
                          >
                            download
                          </v-btn>
                        </div>
                      </template>
                      <span>Only with subscription</span>
                    </v-tooltip>
                    <v-btn
                      v-else
                      color="primary"
                      width="100%"
                      :href="resource.url"
                      @click="SaveDownload(resource)"
                      :loading="resource.loading"
                      :disabled="resource.loading"
                      target="_blank"
                      download
                    >
                      <v-icon>mdi-download</v-icon> Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>              
            </v-row>
          </v-container>
        </v-card>
      </v-tab-item>
      <v-tab-item><!--READING WORDS-->
        <v-card color="basil" flat>
          <v-container>
            <v-row>
              <v-col
                v-for="resource in filteredResources('Reading Words')"
                :key="resource.ResourceID"
                class="mt-5"  
                cols="6"            
                v-show="resourceShowCondition(resource)"
              >
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    :src="require('../../../assets/admin/'+resource.imgSrc)"
                    :height="resource.Height"
                  ></v-img>
                  
                  <p class="text-center subtitle-1 cyan lighten-5 mb-0 font-weight-bold">
                    {{ resource.Title }}
                  </p>
                  <v-card-actions>
                    <v-tooltip v-if="resource.demo && demo" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="display: inline-block; width: 100%;">
                          <v-btn
                            color="primary"
                            width="100%"
                            :href="resource.url"
                            @click="SaveDownload(resource)"
                            :loading="resource.loading"
                            :disabled="resource.loading || resource.demo"
                            target="_blank"
                            download
                          >
                            download
                          </v-btn>
                        </div>
                      </template>
                      <span>Only with subscription</span>
                    </v-tooltip>
                    <v-btn
                      v-else
                      color="primary"
                      width="100%"
                      :href="resource.url"
                      @click="SaveDownload(resource)"
                      :loading="resource.loading"
                      :disabled="resource.loading"
                      target="_blank"
                      download
                    >
                      <v-icon>mdi-download</v-icon> Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>              
            </v-row>  
          </v-container>
        </v-card>
      </v-tab-item><!--END READING WORDS-->
      <v-tab-item v-if="!free">
        <v-card color="basil" flat>
          <v-container>
            <v-row>
              <v-col
                v-for="(resource, index) in filteredResources('Achievement Certificates')"
                :key="resource.ResourceID"                   
                :cols="index === 3 ? 12 : 4"                           
                v-show="resourceShowCondition(resource)"
              >
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    :src="require('../../../assets/admin/'+resource.imgSrc)"
                    :height="resource.Height"
                  ></v-img>
                  
                  <p class="text-center subtitle-1 cyan lighten-5 mb-0 font-weight-bold">
                    {{ resource.Title }}
                  </p>
                  <v-card-actions>
                    <v-tooltip v-if="resource.demo && demo" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="display: inline-block; width: 100%;">
                          <v-btn
                            color="primary"
                            width="100%"
                            :href="resource.url"
                            @click="SaveDownload(resource)"
                            :loading="resource.loading"
                            :disabled="resource.loading || resource.demo"
                            target="_blank"
                            download
                          >
                            download
                          </v-btn>
                        </div>
                      </template>
                      <span>Only with subscription</span>
                    </v-tooltip>
                    <v-btn
                      v-else
                      color="primary"
                      width="100%"
                      :href="resource.url"
                      @click="SaveDownload(resource)"
                      :loading="resource.loading"
                      :disabled="resource.loading"
                      target="_blank"
                      download
                    >
                      <v-icon>mdi-download</v-icon> Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>              
            </v-row>
          </v-container>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="!free">
        <v-card color="basil" flat>
          <v-container>
            <v-row>
              <v-col
                v-for="(resource, index) in filteredResources('Lee Lee Scope and sequence')"
                :key="resource.ResourceID"                   
                :cols="index === 3 ? 12 : 4"                           
                v-show="resourceShowCondition(resource)"
              >
                <v-card class="mx-auto" max-width="250">
                  <v-img
                    :src="require('../../../assets/admin/'+resource.imgSrc)"
                    :height="resource.Height"
                  ></v-img>
                  
                  <p class="text-center subtitle-1 cyan lighten-5 mb-0 font-weight-bold">
                    {{ resource.Title }}
                  </p>
                  <v-card-actions>
                    <v-tooltip v-if="resource.demo && demo" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="display: inline-block; width: 100%;">
                          <v-btn
                            color="primary"
                            width="100%"
                            :href="resource.url"
                            @click="SaveDownload(resource)"
                            :loading="resource.loading"
                            :disabled="resource.loading || resource.demo"
                            target="_blank"
                            download
                          >
                            download
                          </v-btn>
                        </div>
                      </template>
                      <span>Only with subscription</span>
                    </v-tooltip>
                    <v-btn
                      v-else
                      color="primary"
                      width="100%"
                      :href="resource.url"
                      @click="SaveDownload(resource)"
                      :loading="resource.loading"
                      :disabled="resource.loading"
                      target="_blank"
                      download
                    >
                      <v-icon>mdi-download</v-icon> Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>              
            </v-row>
          </v-container>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="!free">
        <v-card color="basil" flat>
          <v-container>          
            
            <v-row>
              <template v-for="(resource, index) in filteredResources('Lee Lee Phonics BINGO')">
                <!-- Título cada 3 elementos -->
                <v-col v-if="index > 0 && (index - 1) % 3 === 0" :key="'title-' + index" cols="12" v-show="resourceShowCondition(resource)">
                  <div
                    style="
                      width: 80%;
                      height: 20px;
                      border-bottom: 1px solid black;
                      text-align: center;
                      margin: 20px auto 15px auto;">
                    <span style="font-size: 25px; background-color: #fff; padding: 0 20px" v-if="resource.Level=='L1'">
                      {{ (index - 1) % 9 === 0 ? 'Sonido de las letras' : (index - 1) % 9 === 3 ? 'Sílabas directas' : 'Sílabas inversas' }}
                    </span>
                    <span style="font-size: 25px; background-color: #fff; padding: 0 20px" v-else="resource.Level=='L1'">
                      {{ (index - 1) % 9 === 0 ? 'Sílabas directas' : 'Sílabas inversas' }}
                    </span>
                  </div>
                </v-col>

                <!-- Contenido del v-col -->
                <v-col :key="resource.ResourceID" :cols="index === 0 ? 12 : 4" v-show="resourceShowCondition(resource)">
                  <v-card class="mx-auto" max-width="250">
                    <v-img
                      :src="require('../../../assets/admin/'+resource.imgSrc)"
                      :height="resource.Height"
                    ></v-img>
                    
                    <p class="text-center subtitle-1 cyan lighten-5 mb-0 font-weight-bold">
                      {{ resource.Title }}
                    </p>
                    <v-card-actions>
                      <v-tooltip v-if="resource.demo && demo" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on" style="display: inline-block; width: 100%;">
                            <v-btn
                              color="primary"
                              width="100%"
                              :href="resource.url"
                              @click="SaveDownload(resource)"
                              :loading="resource.loading"
                              :disabled="resource.loading || resource.demo"
                              target="_blank"
                              download
                            >
                              download
                            </v-btn>
                          </div>
                        </template>
                        <span>Only with subscription</span>
                      </v-tooltip>
                      <v-btn
                        v-else
                        color="primary"
                        width="100%"
                        :href="resource.url"
                        @click="SaveDownload(resource)"
                        :loading="resource.loading"
                        :disabled="resource.loading"
                        target="_blank"
                        download
                      >
                        <v-icon>mdi-download</v-icon> Download
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </template>              
            </v-row>            
          </v-container>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="!free">
        <v-card color="basil" flat>
          <v-container>
            <v-row>
              <v-col
                v-for="resource in filteredResources('Other Resources')"
                :key="resource.ResourceID"
                class="mt-5"                
                v-show="resourceShowCondition(resource)"
              >
                <v-card class="mx-auto" max-width="300">
                  <v-img
                    :src="require('../../../assets/admin/'+resource.imgSrc)"
                    :height="resource.Height"
                  ></v-img>

                  <p class="text-center text-h6 mb-0">{{ resource.Title }}</p>
                  <p class="text-center subtitle-1 cyan lighten-5 mb-0 font-weight-bold">
                    {{ resource.Subtitle }}
                  </p>
                  <v-card-actions>
                    <v-tooltip v-if="resource.demo && demo" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="display: inline-block; width: 100%;">
                          <v-btn
                            color="primary"
                            width="100%"
                            :href="resource.url"
                            @click="SaveDownload(resource)"
                            :loading="resource.loading"
                            :disabled="resource.loading || resource.demo"
                            target="_blank"
                            download
                          >
                            download
                          </v-btn>
                        </div>
                      </template>
                      <span>Only with subscription</span>
                    </v-tooltip>
                    <v-btn
                      v-else
                      color="primary"
                      width="100%"
                      :href="resource.url"
                      @click="SaveDownload(resource)"
                      :loading="resource.loading"
                      :disabled="resource.loading"
                      target="_blank"
                      download
                    >
                      <v-icon>mdi-download</v-icon> Download
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>              
            </v-row>
          </v-container>
        </v-card>
      </v-tab-item>
    <!-- </v-tabs-items> -->
    </v-tabs>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { db } from "../../../plugins/firebase";
import firebase from "firebase";
export default {
  data() {
    return {
      tab: "",      
      free: true,
      demo: false,
      L1: false,
      L2: false,
      gradeList: ['Pre-School', 'Kindergarten', '1st Grade', '2nd Grade', '3rd Grade', '4th Grade', '5th Grade', '6th Grade', , '7th Grade', , '8th Grade'],
      resources: [
        {"ResourceID": "RCSSG01", "Title": "Guía del estudiante Lee Lee K-2", "Subtitle": "Los sonidos", "Doc": "Guia del estudiante K-2 Los sonidos de las letras.pdf", "Cat": "STUDENT GUIDES", "imgSrc": "guia-estudio-k2.png" ,"Level": "L1", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "350px"},        
		
        {"ResourceID": "RCSSG02", "Title": "Sílabas inversas - directas", "Subtitle": "", "Doc": "Cartilla de Silabas Inversas - Directas.pdf", "Cat": "Syllable type cards", "imgSrc": "cartillas-silabas-inversas-directas.png" ,"Level": "L1", "Show": true ,"demo": false, "url": "", "loading": true, "Height": "350px"},
        {"ResourceID": "RCSSG03", "Title": "Sílabas trabadas", "Subtitle": "", "Doc": "Cartilla de Silabas Trabadas.pdf", "Cat": "Syllable type cards", "imgSrc": "cartillas-silabas-trabadas.png" ,"Level": "L1", "Show": true ,"demo": false, "url": "", "loading": true, "Height": "350px"},
        {"ResourceID": "RCSSG04", "Title": "Sílabas directas", "Subtitle": "", "Doc": "Cartilla silabas directas.pdf", "Cat": "Syllable type cards", "imgSrc": "cartillas-silabas-directas.png" ,"Level": "L1", "Show": true ,"demo": false, "url": "", "loading": true, "Height": "350px"},
        {"ResourceID": "RCSSG05", "Title": "Sílabas inversas", "Subtitle": "", "Doc": "Cartilla Silabas Inversas.pdf", "Cat": "Syllable type cards", "imgSrc": "cartillas-silabas-inversas.png" ,"Level": "L1", "Show": true ,"demo": false, "url": "", "loading": true, "Height": "350px"},
		
        {"ResourceID": "RCSPC01", "Title": "Phonetic articulation cards", "Subtitle": "", "Doc": "Phonetic articulation cards.pdf",  "Cat": "Phonics Cards", "imgSrc": "phonetic-articulation-cards.png" ,"Level": "L1", "Show": true ,"demo": false, "url": "", "loading": true, "Height": "230px"},
        {"ResourceID": "RCSPC02", "Title": "Figuras y Letras Completa", "Subtitle": "", "Doc": "Cartilla con letras y dibulos completa.pdf",  "Cat": "Phonics Cards", "imgSrc": "dibujoyfigura.png" ,"Level": "L1", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "230px"},
        {"ResourceID": "RCSPC03", "Title": "Solo Letras Completa", "Subtitle": "", "Doc": "Cartilla solo letras completa.pdf",  "Cat": "Phonics Cards", "imgSrc": "Letra.png" ,"Level": "L1", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "230px"},
        {"ResourceID": "RCSPC04", "Title": "Figuras y Letras", "Subtitle": "", "Doc": "Cartilla con letras y dibujos individual.pdf",  "Cat": "Phonics Cards", "imgSrc": "dibujoyfigura.png" ,"Level": "L1", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "230px"},
        {"ResourceID": "RCSPC05", "Title": "Solo Letras", "Subtitle": "", "Doc": "Cartilla solo letras individual.pdf",  "Cat": "Phonics Cards", "imgSrc": "Letra.png" ,"Level": "L1", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "230px"},

        {"ResourceID": "RCSRW01", "Title": "Palabras de 2 sílabas", "Subtitle": "", "Doc": "Lee Lee Palabras de 2 Sílabas.pdf",  "Cat": "Reading Words", "imgSrc": "palabras2silabas.png" ,"Level": "L1", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "190px"},
        {"ResourceID": "RCSRW02", "Title": "Palabras de 2 sílabas con dibujo", "Subtitle": "", "Doc": "Lee Lee Palabras de 2 Sílabas con dibujos.pdf",  "Cat": "Reading Words", "imgSrc": "palabras2silabasdibujo.png" ,"Level": "L1", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "190px"},
        {"ResourceID": "RCSRW03", "Title": "Palabras de 3 sílabas", "Subtitle": "", "Doc": "Lee Lee Palabras 3 Sílabas.pdf",  "Cat": "Reading Words", "imgSrc": "palabras3silabas.png" ,"Level": "L1", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "190px"},
        {"ResourceID": "RCSRW04", "Title": "Palabras con sílabas trabadas", "Subtitle": "", "Doc": "Lee Lee Palabras con Sílabas Trabadas.pdf",  "Cat": "Reading Words", "imgSrc": "palabrassilabastrabadas.png" ,"Level": "L1", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "190px"},

        {"ResourceID": "RCSAC01", "Title": "Mision 1", "Subtitle": "Sonidos Iniciales", "Doc": "Mision 1.pdf",  "Cat": "Achievement Certificates", "imgSrc": "certificadoM1.png" ,"Level": "L1", "Show": true ,"demo": false, "url": "", "loading": true, "Height": "190px"},
        {"ResourceID": "RCSAC02", "Title": "Mision 2", "Subtitle": "Las Sílabas", "Doc": "Mision 2.pdf",  "Cat": "Achievement Certificates", "imgSrc": "certificadoM2.png" ,"Level": "L1", "Show": true ,"demo": false, "url": "", "loading": true, "Height": "190px"},
        {"ResourceID": "RCSAC03", "Title": "Mision 3", "Subtitle": "Las Palabras", "Doc": "Mision 3.pdf",  "Cat": "Achievement Certificates", "imgSrc": "certificadoM3.png" ,"Level": "L1", "Show": true ,"demo": false, "url": "", "loading": true, "Height": "190px"},
        {"ResourceID": "RCSAC04", "Title": "Mision 4", "Subtitle": "Extensiones, sílabas inversas, sílabas con inicio", "Doc": "Mision 4.pdf",  "Cat": "Achievement Certificates", "imgSrc": "certificadoM4.png" ,"Level": "L1", "Show": true ,"demo": false, "url": "", "loading": true, "Height": "190px"},
        {"ResourceID": "RCSAC05", "Title": "Mision 5", "Subtitle": "Tres Sílabas", "Doc": "Mision 5.pdf",  "Cat": "Achievement Certificates", "imgSrc": "certificadoM5.png" ,"Level": "L1", "Show": true ,"demo": false, "url": "", "loading": true, "Height": "190px"},
        {"ResourceID": "RCSAC06", "Title": "Mision 6", "Subtitle": "Sílabas trabadas", "Doc": "Mision 6.pdf",  "Cat": "Achievement Certificates", "imgSrc": "certificadoM6.png" ,"Level": "L1", "Show": true ,"demo": false, "url": "", "loading": true, "Height": "190px"},
        {"ResourceID": "RCSAC07", "Title": "Mision 7", "Subtitle": "Lectura de Oraciones", "Doc": "Mision 7.pdf",  "Cat": "Achievement Certificates", "imgSrc": "certificadoM7.png" ,"Level": "L1", "Show": true ,"demo": false, "url": "", "loading": true, "Height": "190px"},
		
        {"ResourceID": "RCSSS01", "Title": "Pre-Kinder", "Subtitle": "", "Doc": "Programa de tiempos de Ensenanza Lee Lee PreKinder.pdf",  "Cat": "Lee Lee Scope and sequence", "imgSrc": "calendario-Pre-Kinder.png" ,"Level": "L1", "Show": true ,"demo": false, "url": "", "loading": true, "Height": "190px"},
        {"ResourceID": "RCSSS02", "Title": "Kinder", "Subtitle": "", "Doc": "Programa de tiempos de Ensenanza Lee Lee Kinder.pdf",  "Cat": "Lee Lee Scope and sequence", "imgSrc": "calendario-Kinder.png" ,"Level": "L1", "Show": true ,"demo": false, "url": "", "loading": true, "Height": "190px"},
        {"ResourceID": "RCSSS03", "Title": "1st. - 2nd. grade", "Subtitle": "", "Doc": "Programa de tiempos de Ensenanza Lee Lee 1st a 2nd.pdf",  "Cat": "Lee Lee Scope and sequence", "imgSrc": "calendario-1-2grade.png" ,"Level": "L1", "Show": true ,"demo": false, "url": "", "loading": true, "Height": "190px"},
        {"ResourceID": "RCSSS04", "Title": "3rd. - 5th. Grade", "Subtitle": "", "Doc": "Programa de tiempos de Ensenanza Lee Lee 3rd - 5th grade.pdf",  "Cat": "Lee Lee Scope and sequence", "imgSrc": "calendario-3-5grade.png" ,"Level": "L2", "Show": false ,"demo": false, "url": "", "loading": true, "Height": "190px"},
		
        {"ResourceID": "RCSPB01", "Title": "Instrucciones", "Subtitle": "", "Doc": "Instrucciones.pdf",  "Cat": "Lee Lee Phonics BINGO", "imgSrc": "bingo-instrucciones.png" ,"Level": "L1", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "190px"},
        {"ResourceID": "RCSPB02", "Title": "Base Guia", "Subtitle": "", "Doc": "Base bingo sonidos de las letras.pdf",  "Cat": "Lee Lee Phonics BINGO", "imgSrc": "bingo-base.png" ,"Level": "L1", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "190px"},
        {"ResourceID": "RCSPB03", "Title": "Tarjetas para Cantar", "Subtitle": "", "Doc": "Tarjetas para cantar Sonido de las letras.pdf",  "Cat": "Lee Lee Phonics BINGO", "imgSrc": "bingo-canto.png" ,"Level": "L1", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "190px"},
        {"ResourceID": "RCSPB04", "Title": "Tarjetas de Bingo", "Subtitle": "", "Doc": "Cartillas bingo Sonido de las letras.pdf",  "Cat": "Lee Lee Phonics BINGO", "imgSrc": "bingo-tarjetas.png" ,"Level": "L1", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "190px"},
        {"ResourceID": "RCSPB05", "Title": "Base Guia", "Subtitle": "", "Doc": "Base Bingo Silabas Inversas.pdf",  "Cat": "Lee Lee Phonics BINGO", "imgSrc": "base-para-bingo-silabas-directas.png" ,"Level": "L1", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "190px"},
        {"ResourceID": "RCSPB06", "Title": "Tarjetas para Cantar", "Subtitle": "", "Doc": "Tarjetas para cantar el bingo.pdf",  "Cat": "Lee Lee Phonics BINGO", "imgSrc": "cartas-para-cantar-silabas-directas.png" ,"Level": "L1", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "190px"},
        {"ResourceID": "RCSPB07", "Title": "Tarjetas de Bingo", "Subtitle": "", "Doc": "Bingo Silabas Inversas Cartillas.pdf",  "Cat": "Lee Lee Phonics BINGO", "imgSrc": "cartas-silabas-directas.png" ,"Level": "L1", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "190px"},
        {"ResourceID": "RCSPB08", "Title": "Base Guia", "Subtitle": "", "Doc": "BASE PARA BINGO SILABAS DIRECTAS.pdf",  "Cat": "Lee Lee Phonics BINGO", "imgSrc": "bingo-base-silabas-inversas.png" ,"Level": "L1", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "190px"},
        {"ResourceID": "RCSPB09", "Title": "Tarjetas para Cantar", "Subtitle": "", "Doc": "CARTAS PARA CANTAR SILABAS BINGO SILABAS DIRECTAS.pdf",  "Cat": "Lee Lee Phonics BINGO", "imgSrc": "bingo-canto-silabas-inversas.png" ,"Level": "L1", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "190px"},
        {"ResourceID": "RCSPB10", "Title": "Tarjetas de Bingo", "Subtitle": "", "Doc": "CARTAS SILABAS DIRECTAS.pdf",  "Cat": "Lee Lee Phonics BINGO", "imgSrc": "bingo-tarjetas-silabas-inversas.png" ,"Level": "L1", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "190px"},
		
        {"ResourceID": "RCSOR01", "Title": "Encuentra las Palabras 2 sílabas", "Subtitle": "", "Doc": "Encuentra las palabras 2 silabas AAL.pdf",  "Cat": "Other Resources", "imgSrc": "encuentra_palabras_silabas.png" ,"Level": "L1", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "230px"},
        {"ResourceID": "RCSOR02", "Title": "Caligrafía y lectura mediante trazos Letras-Sílabas-oraciones", "Subtitle": "", "Doc": "Trazar letras_silabas y oraciones AAL.pdf",  "Cat": "Other Resources", "imgSrc": "caligrafia_trazos.png" ,"Level": "L1", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "230px"},

        //V2
        {"ResourceID": "RCSSG01_AE", "Title": "Guía del estudiante Lee Lee", "Subtitle": "Sílabas", "Doc": "Guia del Estudiante - Silabas y Palabras V1.0.pdf", "Cat": "STUDENT GUIDES", "imgSrc": "guia-estudio-3-5.png" ,"Level": "L1|L2", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "350px"},
        {"ResourceID": "RCSSG02_AE", "Title": "Sílabas inversas - directas", "Subtitle": "", "Doc": "Cartilla de Silabas Inversas - Directas AE.pdf", "Cat": "Syllable type cards", "imgSrc": "SyllableTypeCards_1.jpg" ,"Level": "L2", "Show": true ,"demo": false, "url": "", "loading": true, "Height": "350px"},
        {"ResourceID": "RCSSG03_AE", "Title": "Sílabas trabadas", "Subtitle": "", "Doc": "Cartilla de Silabas Trabadas AE.pdf", "Cat": "Syllable type cards", "imgSrc": "SyllableTypeCards_2.jpg" ,"Level": "L2", "Show": true ,"demo": false, "url": "", "loading": true, "Height": "350px"},
        {"ResourceID": "RCSSG04_AE", "Title": "Sílabas directas", "Subtitle": "", "Doc": "Cartilla silabas directas AE.pdf", "Cat": "Syllable type cards", "imgSrc": "SyllableTypeCards_3.jpg" ,"Level": "L2", "Show": true ,"demo": false, "url": "", "loading": true, "Height": "350px"},
        {"ResourceID": "RCSSG05_AE", "Title": "Sílabas inversas", "Subtitle": "", "Doc": "Cartilla Silabas Inversas AE.pdf", "Cat": "Syllable type cards", "imgSrc": "SyllableTypeCards_4.jpg" ,"Level": "L2", "Show": true ,"demo": false, "url": "", "loading": true, "Height": "350px"},
		
        {"ResourceID": "RCSPC01_AE", "Title": "Phonetic articulation cards", "Subtitle": "", "Doc": "Phonetic articulation cards AE.pdf",  "Cat": "Phonics Cards", "imgSrc": "PhonicsCards1.jpg" ,"Level": "L2", "Show": true ,"demo": false, "url": "", "loading": true, "Height": "190px"},
        {"ResourceID": "RCSPC03_AE", "Title": "Solo Letras Completa", "Subtitle": "", "Doc": "Cartilla solo letras completa AE.pdf",  "Cat": "Phonics Cards", "imgSrc": "PhonicsCards2.jpg" ,"Level": "L2", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "350px"},

        {"ResourceID": "RCSRW01_AE", "Title": "Palabras de 2 sílabas", "Subtitle": "", "Doc": "Lee Lee Palabras de 2 Sílabas AE.pdf",  "Cat": "Reading Words", "imgSrc": "ReadingWords_1.jpg" ,"Level": "L2", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "190px"},        
        {"ResourceID": "RCSRW03_AE", "Title": "Palabras de 3 sílabas", "Subtitle": "", "Doc": "Lee Lee Palabras 3 silabas AE.pdf",  "Cat": "Reading Words", "imgSrc": "ReadingWords_2.jpg" ,"Level": "L2", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "190px"},
        {"ResourceID": "RCSRW04_AE", "Title": "Palabras con sílabas trabadas", "Subtitle": "", "Doc": "Lee Lee Palabras con Silabas Trabadas AE.pdf",  "Cat": "Reading Words", "imgSrc": "ReadingWords_3.jpg" ,"Level": "L2", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "190px"},

        {"ResourceID": "RCSSS04_AE", "Title": "3rd. - 5th. Grade", "Subtitle": "", "Doc": "Programa de tiempos de Ensenanza Lee Lee 3rd - 5th grade AE.pdf",  "Cat": "Lee Lee Scope and sequence", "imgSrc": "LeeLeeScoupeSecuencia.jpg" ,"Level": "L2", "Show": true ,"demo": false, "url": "", "loading": true, "Height": "190px"},

        {"ResourceID": "RCSOR01_AE", "Title": "Encuentra las Palabras 2 sílabas", "Subtitle": "", "Doc": "Encuentra las palabras 2 silabas AE.pdf",  "Cat": "Other Resources", "imgSrc": "OtrosResources1.jpg" ,"Level": "L2", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "230px"},
        {"ResourceID": "RCSOR02_AE", "Title": "Caligrafía y lectura mediante trazos Letras-Sílabas-oraciones", "Subtitle": "", "Doc": "Trazar letras_silabas y oraciones AE.pdf",  "Cat": "Other Resources", "imgSrc": "OtrosResources2.jpg" ,"Level": "L2", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "350px"},

       
        {"ResourceID": "RCSPB05_AE", "Title": "Base Guia", "Subtitle": "", "Doc": "Base para bingo Silabas Directas AE.pdf",  "Cat": "Lee Lee Phonics BINGO", "imgSrc": "PhonicsBingo3AE.jpg" ,"Level": "L2", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "190px"},
        {"ResourceID": "RCSPB06_AE", "Title": "Tarjetas para Cantar", "Subtitle": "", "Doc": "Tarjetas para cantar Silabas directas AE.pdf",  "Cat": "Lee Lee Phonics BINGO", "imgSrc": "PhonicsBingo4AE.jpg" ,"Level": "L2", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "190px"},
        {"ResourceID": "RCSPB07_AE", "Title": "Tarjetas de Bingo", "Subtitle": "", "Doc": "Cartillas Bingo Silabas Directas AE.pdf",  "Cat": "Lee Lee Phonics BINGO", "imgSrc": "PhonicsBingo6AE.jpg" ,"Level": "L2", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "190px"},
        {"ResourceID": "RCSPB08_AE", "Title": "Base Guia", "Subtitle": "", "Doc": "Base para bingo Silabas Inversas AE.pdf",  "Cat": "Lee Lee Phonics BINGO", "imgSrc": "PhonicsBingo1AE.jpg" ,"Level": "L2", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "190px"},
        {"ResourceID": "RCSPB09_AE", "Title": "Tarjetas para Cantar", "Subtitle": "", "Doc": "Tarjetas para cantar Silabas inversas AE.pdf",  "Cat": "Lee Lee Phonics BINGO", "imgSrc": "PhonicsBingo2AE.jpg" ,"Level": "L2", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "190px"},
        {"ResourceID": "RCSPB10_AE", "Title": "Tarjetas de Bingo", "Subtitle": "", "Doc": "Cartillas Bingo Silabas Inversas AE.pdf",  "Cat": "Lee Lee Phonics BINGO", "imgSrc": "PhonicsBingo5AE.jpg" ,"Level": "L2", "Show": true ,"demo": true, "url": "", "loading": true, "Height": "190px"},
      ]
    };
  },
  created() {},
  computed: {
    ...mapState('userTeacher', ['userTeacher'])
  },
  watch: {
    userTeacher: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.GetDataTeacher();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async GetDataTeacher() {
      let iud = this.$store.state.user.uid;
      let role = this.$store.state.user.role;
      let info = "";
      if (this.userTeacher && Object.keys(this.userTeacher).length) {
        this.demo=this.userTeacher.IsDemo;    console.log('U S U A R I O :', this.userTeacher);
        const Classrooms=this.userTeacher.Classrooms;console.log("Classrooms--------------------------->", this.userTeacher.Classrooms);
        let idsClassrooms = Object.keys(Classrooms);     
        let position=-1;
        const positionReg=this.userTeacher.hasOwnProperty("GradeRegistered")?this.gradeList.indexOf(this.userTeacher.GradeRegistered):-1;
        this.L1=(positionReg>=0 && positionReg<=3);
        this.L2=(positionReg>=4 && positionReg<=9);
        for (const idClassRoom of idsClassrooms) {
          position=this.gradeList.indexOf(Classrooms[idClassRoom].Grade);        
          if (position>=0 && position<=3){
            this.L1=true;
          }
          if (position>=4 && position<=9){
            this.L2=true;
          }
        }
        console.log("position--------------------------------->", position, positionReg, "this.L1* ", this.L1, "this.L2* ", this.L2);
        if (role == "individualTeacher") {
          info = await db.collection("IndividualTeacher").doc(iud).get();
          console.log(info.data());

          if (info.data().Full) {
            this.free = false;
          }
        } else {
          this.free = false;
        }
      } else {
        console.log('userTeacher aún no está disponible');
      }
    },
    filteredResources(category) {
      return this.resources.filter(resource => resource.Cat === category);
    },
    resourceShowCondition(resource) { console.log("resource---------->", resource.Title+' '+resource.Level);      
      // if (this.demo && !this.L1) {this.L1=true; resource.Level='L1'};//Borrar una vez establecido como sera los demos ahora solo muestra L1
      let levelCondition = (this.L1 && resource.Level.includes('L1')) || (this.L2 && resource.Level.includes('L2'));      
      const showCondition = resource.Show;
      console.log("levelCondition && showCondition", levelCondition && showCondition);
      return levelCondition && showCondition;
    },    
    download(resource){      
      var storage = firebase.storage();
      storage
        .ref("Resources/"+resource.Doc)
        .getDownloadURL()
        .then(function (url) {
          resource.loading = false;
          resource.url = url;
        });
    },
    LoadData(){
      this.resources.forEach(resource => {
        this.download(resource);
      });
    },    
    async SaveDownload(resource) {      
      let rol = this.$store.state.user.role;
      let iud = this.$store.state.user.uid;
      let dia = new Date();
      let time = dia.getTime();//console.log(dia.toISOString());      
      if (rol == "schoolTeacher") {
        await db.collection("SchoolTeacher")
          .doc(iud)
          .update({            
            [`Downloads.${resource.ResourceID}.Downloads`]: firebase.firestore.FieldValue.increment(1),
            [`Downloads.${resource.ResourceID}.Title`]: resource.Title,
            [`Downloads.${resource.ResourceID}.Category`]: resource.Cat,
            [`Downloads.${resource.ResourceID}.Date`]: time
          });
      } else {
        await db.collection("IndividualTeacher")
          .doc(iud)
          .update({
            [`Downloads.${resource.ResourceID}.Downloads`]: firebase.firestore.FieldValue.increment(1),
            [`Downloads.${resource.ResourceID}.Title`]: resource.Title,
            [`Downloads.${resource.ResourceID}.Date`]: time
          });
      }
    },
    cambioTab() {
      //console.log('cambio de tab' + this.tab);
      switch (this.tab) {
        case 0:
          //console.log('entro a la primera')
          break;
        case 1:
          //console.log('entro a la segunda')
          //this.openModalListStudents();
          break;
        case 2:
          //console.log('entro a la tercera')
          //   this.openModalQR();
          break;
      }
    },
  },
  mounted() {    
    this.GetDataTeacher(); 
    this.LoadData();   
  },
};
</script>
<style lang="scss">
.style-1 {
  text-align: center;
  background-color: #e0f7fa;
}
.style-1 {
  del {
    color: rgba(red, 0.5);
    text-decoration: none;
    position: relative;
    &:before {
      content: " ";
      display: block;
      width: 100%;
      border-top: 2px solid rgba(red, 0.8);
      height: 12px;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: rotate(-7deg);
    }
  }
  ins {
    color: green;
    font-size: 32px;
    text-decoration: none;
    padding: 1em 1em 1em 0.5em;
  }
}

.child {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;

  /* Text */

  p {
    position: absolute;
    top: 20px;
    right: 0;
    color: white;
    transform: rotate(45deg);
    margin: 0;
    line-height: 1;
    font-size: 15px;
    text-transform: uppercase;
  }
}
.child::before {
  content: "";
  display: block;
  width: 80px;
  height: 80px;
  border-top: solid 40px rgb(200, 30, 50);
  border-right: solid 40px rgb(200, 30, 50);
  border-left: solid 40px transparent;
  border-bottom: solid 40px transparent;
}
.my-tabs{
  height: 100%;  
}
div.my-tabs div[role="tablist"]{
   display: flex; 
   align-items: center;
}
.my-tabs div.v-tabs-items {
    overflow-y:auto;
}
</style>